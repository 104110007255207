import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import Menu from './Menu'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Modal, Button } from 'react-bootstrap';
import { API } from '../../Config'
const Myquiz = () => {
    const [question, setQuestion] = useState([]);
    const [editid, setEditId] = useState([]);
    const [visibleedit, setVisibleedit] = useState(false);
    const [visibladd, setVisibleAdd] = useState(false);
    const [editData, setEditData] = useState([]);

    useEffect(() => {
        // setQuestion(Questions)
        GetQuestion()
    }
        , []);
    const AddQuestion = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const op1 = data.get('A')
        const op2 = data.get('B')
        const op3 = data.get('C')
        const op4 = data.get('D')
        const question = data.get('question')
        const correct_answer = data.get('correct_answer')

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                question: question,
                option1: op1,
                option2: op2,
                option3: op3,
                option4: op4,
                correct_option: correct_answer
            }),
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(`${API.localHost}/add_question`, requestOptions)
        const res = await response.json()
        console.log(res, "res")
        if (res.status === 200) {
            const reponse = res.data

            // setQuestion(reponse)
            setVisibleAdd(false)
            GetQuestion()
        }
    }
    const GetQuestion = async () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(`${API.localHost}/get_all_questions`, requestOptions)
        const res = await response.json()
        console.log(res, "questions")
        if (res.status === 201) {
            const reponse = res.data
            setQuestion(reponse)
        }

    }
    const editQuestion = async (data) => {
        setEditId(data)
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${API.localHost}/get_question_by_id?id=${data}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data.data, "data")

                setEditData(data.data)
            })
        setVisibleedit(true)
    }
    const updateSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const op1 = data.get('A')
        const op2 = data.get('B')
        const op3 = data.get('C')
        const op4 = data.get('D')
        const question = data.get('question')
        const correct_answer = data.get('correct_answer')

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify({
                question: question,
                option1: op1,
                option2: op2,
                option3: op3,
                option4: op4,
                correct_option: correct_answer
            }),
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(`${API.localHost}/update_question?id=${editid}`, requestOptions)
        const res = await response.json()
        console.log(res, "res")
        if (res.status === 200) {
            const reponse = res.data
            GetQuestion()
            setVisibleedit(false)
        }
    }
    const deleteItem = async (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(`${API.localHost}/delete_question?id=${id}`, requestOptions)
        const res = await response.json()
        console.log(res, "res")
        if (res.status === 200) {
            GetQuestion()
        }
    }
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Questions</h1>
                                <button className="btn btn-success my-1" onClick={() => setVisibleAdd(true)}>Add Question</button>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            <i className="fas fa-home" /> Admin Panel
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active">Quiz</li>
                                </ol>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <table className="table table-bordered">
                                <thead className="thead-dark">
                                    <tr className="table-info">
                                        <th>#</th>
                                        <th>Question</th>
                                        <th>A</th>
                                        <th>B</th>
                                        <th>C</th>
                                        <th>D</th>
                                        <th>Correct Answer</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {question?.length > 0 ?
                                        <>

                                            {question?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.question}</td>
                                                    <td>{item.option1}</td>
                                                    <td>{item.option2}</td>
                                                    <td>{item.option3}</td>
                                                    <td>{item.option4}</td>
                                                    <td>{item.correct_option}</td>
                                                    <td>
                                                        <button className="btn btn-primary"
                                                            onClick={() => { editQuestion(item._id); }}
                                                        >Edit</button>

                                                        {' '}
                                                        <button className="btn btn-danger m-1"
                                                            onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteItem(item._id) }}
                                                        >Delete</button>
                                                    </td>
                                                </tr>
                                            ))}

                                        </>
                                        :
                                        <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </div>

                <Modal size='lg' show={visibleedit} onHide={() => setVisibleedit(false)}>
                    <Modal.Header >
                        <Modal.Title>Edit User</Modal.Title>
                        <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                            Close
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form action="/" method="POST" onSubmit={updateSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Question</label>
                                                            <input type="text" className="form-control" placeholder="Enter Question" name="question" autoComplete="off" defaultValue={editData?.question} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Option A</label>
                                                            <input type="text" className="form-control" placeholder="Enter Option A" name="A" autoComplete="off" defaultValue={editData?.option1} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Option B</label>
                                                            <input type="text" className="form-control" placeholder="Enter Option B" name="B" autoComplete="off" defaultValue={editData?.option2} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Option C</label>
                                                            <input type="text" className="form-control" placeholder="Enter Option C" name="C" autoComplete="off" defaultValue={editData?.option3} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Option D</label>
                                                            <input type="text" className="form-control" placeholder="Enter Option D" name="D" autoComplete="off" defaultValue={editData?.option4} />
                                                        </div>
                                                    </div>


                                                </div>
                                                <label>Select Correct Answer</label>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label>A</label>
                                                        <input type="radio" defaultChecked={editData.correct_option === "A" ? true : false} className='mx-2' name="correct_answer" value="A" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>B</label>
                                                        <input type="radio" defaultChecked={editData.correct_option === "B" ? true : false} className='mx-2' name="correct_answer" value="B" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>C</label>
                                                        <input type="radio" defaultChecked={editData.correct_option === "C" ? true : false} className='mx-2' name="correct_answer" value="C" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>D</label>
                                                        <input type="radio" defaultChecked={editData.correct_option === "D" ? true : false} className='mx-2' name="correct_answer" value="D" />
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    {/* <div className='row my-3'>
                                                    <div className="col-md-2">
                                                    <label>All</label>
                                                    <input type="radio" defaultChecked={editData.correct_option === "All" ?true:false} className='mx-2' name="correct_answer" value="All" />
                                                    </div>
                                                </div> */}
                                                    {/* <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Correct Answer</label>
                                                            <input type="text" className="form-control" placeholder="Enter Correct Answer" name="correct_answer" autoComplete="off" defaultValue={editData?.correct_option} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn btn-primary mt-2 submit_all" style={{ float: "right" }}>Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                            Close
                        </Button>
                    </Modal.Footer> */}
                </Modal>
                <Modal size='lg' show={visibladd} onHide={() => setVisibleAdd(false)}>
                    <Modal.Header >
                        <Modal.Title>Add User</Modal.Title>
                        <Button variant="secondary" onClick={() => setVisibleAdd(false)}>
                            Close
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form action="/" method="POST" onSubmit={AddQuestion}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Question</label>
                                                            <input type="text" className="form-control" required placeholder="Enter Question" name="question" autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Option A</label>
                                                            <input type="text" className="form-control" required placeholder="Enter Option A" name="A" autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Option B</label>
                                                            <input type="text" className="form-control" required placeholder="Enter Option B" name="B" autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Option C</label>
                                                            <input type="text" className="form-control" required placeholder="Enter Option C" name="C" autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Option D</label>
                                                            <input type="text" className="form-control" required placeholder="Enter Option D" name="D" autoComplete="off" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <label>Select Correct Answer</label>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label>A</label>
                                                        <input type="radio" className='mx-2' required name="correct_answer" value="A" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>B</label>
                                                        <input type="radio" className='mx-2' required name="correct_answer" value="B" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>C</label>
                                                        <input type="radio" className='mx-2' required name="correct_answer" value="C" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>D</label>
                                                        <input type="radio" className='mx-2' required name="correct_answer" value="D" />
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>

                                                </div>
                                                {/* <div className='row my-2'>
                                                    <div className="col-md-2">
                                                    <label>All</label>
                                                    <input type="radio" className='mx-2' required name="correct_answer" value="All" />
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn btn-primary mt-2 submit_all" style={{ float: "right" }}>Add</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={() => setVisibleAdd(false)}>
                            Close
                        </Button>
                    </Modal.Footer> */}
                </Modal>
            </div>
            <Footer />
        </div>
    )
}

export default Myquiz
