import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from "../components/master/Main";
import Login from "../components/login/Login";
import Myquiz from "../components/master/Myquiz";
import Myusers from "../components/master/Myusers";
import Myjhankis from "../components/master/Myjhankis";

const Myroutes = () => {
    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route path="/Main">
                        <Main />
                    </Route>
                    <Route path="/Myquiz">
                        <Myquiz />
                    </Route>
                    <Route path="/Myusers">
                        <Myusers />
                    </Route>
                    <Route path="/Myjhankis">
                        <Myjhankis />
                    </Route>
                    <Route exact path="/" component={Login} />
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default Myroutes
