import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg"
import { API } from "../../Config";
export default function Menu() {
  const [adminDetails, setAdminDetails] = useState([]);
  useEffect(() => {
    const id = localStorage.getItem('adminid')
    GetAdminDetails(id)
  }, []);
  const GetAdminDetails = async (id) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const response = await fetch(`${API.localHost}/get_admin_by_id?id=${id}`, requestOptions)
    const res = await response.json()
    console.log(res, "res")
    if (res.status === 200) {
      const reponse = res.data
      setAdminDetails(reponse)
    }

  }
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <Link to="/" className="brand-link">
        <img
          src={logo}
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: "1" }}
        />
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={`${API.baseUrl}/uploads/userImages/` + adminDetails?.profile_image}
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <a href="#" className="d-block">
              {adminDetails?.first_name} {adminDetails?.last_name}
            </a>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar nav-legacy flex-column" data-widget="treeview" role="menu">
            <li className="nav-header">NAVIGATION</li>
            <li className="nav-item">
              <Link to="/Main" className="nav-link">
                <i className="fas fa-home" />&nbsp; <p>Dashboard</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Myusers" className="nav-link">
                <i className="fas fa-user" />&nbsp; <p>Users</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Myjhankis" className="nav-link">
                <i className="fas fa-user" />&nbsp; <p>Jhankis</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Myquiz" className="nav-link">
                <i className="fas fa-user" />&nbsp; <p>Questions</p>
              </Link>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}
