import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { API } from "../../Config";

export default function Content() {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [perPage] = useState(10);
  const [masterData, setMasterData] = useState();
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    const id = localStorage.getItem("adminid");
    if (!id) {
      window.location.href = "/";
    } else {
      GetLeaderboard(page, perPage);
      GetMasterData();
    }
  }
    , []);
  const GetMasterData = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(`${API.localHost}/get_dashboardData`, requestOptions);
    const res = await response.json();
    if (res.status === 200) {
      const reponse = res.data;
      console.log(reponse.userLength[0].count, "reponse")
      setMasterData(reponse);
    }
  };
  const GetLeaderboard = async (page, perPage) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(`${API.localHost}/get_quiz_leaderboard?page=${page}&limit=${perPage}`, requestOptions);
    const res = await response.json();
    if (res.status === 200) {
      const reponse = res?.data;
      const total = reponse[0]?.totalCount[0].total;
      const slice = total / perPage;
      const pages = Math.ceil(slice);
      setPageCount(pages)
      setLeaderboard(reponse[0]?.data);
    }
  }
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage + 1);
    GetLeaderboard(selectedPage + 1, perPage);
  };
  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Dashboard</h1>
            </div>
            {/* /.col */}
            {/* <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fas fa-home" /> Admin Panel
                  </Link>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div> */}
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="small-box bg-orange">
                <div className="inner">
                  <h3>{masterData?.userLength[0]?.count}</h3>
                  <p>Total Users</p>
                </div>
                <div className="icon">
                  <i className="fas fa-user" />
                </div>
                <Link to="/Myusers" className="small-box-footer">View Users <i className="fas fa-user" /></Link>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="small-box bg-white">
                <div className="inner">
                  <h3>{masterData?.jhankiLength[0]?.count}</h3>
                  <p>Total Jhankis</p>
                </div>
                <div className="icon">
                  <i className="fas fa-image" />
                </div>
                <Link to="/Myjhankis" className="small-box-footer">View Jhankis <i className="fas fa-image" /></Link>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{masterData?.questionLength[0]?.count}</h3>
                  <p>Questions</p>
                </div>
                <div className="icon">
                  <i className="fas fa-question" />
                </div>
                <Link to="/Myquiz" className="small-box-footer">View Questions<i className="fas fa-question" /></Link>
              </div>
            </div>
          </div>
          <div className="row">
            <h2 className="m-0 text-dark">Leaderboard</h2>
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr className="table-info">
                  <th>#</th>
                  <th> Name</th>
                  <th>Score</th>
                  <th>Correct</th>
                  <th>Wrong</th>
                  <th>Skipped</th>
                </tr>
              </thead>
              <tbody>
                {leaderboard?.length > 0 ?
                  <>

                    {leaderboard?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.user.first_name}{item?.user.laset_name}</td>
                        <td>{item?.score}</td>
                        <td>{item?.correct}</td>
                        <td>{item?.wrong}</td>
                        <td>{item?.skipped}</td>
                      </tr>
                    ))}

                  </>
                  :
                  <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                }

              </tbody>
            </table>
            <div className="card-footer">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>

      {/* /.content */}
    </div>
  );
}
