import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import logo from "../../images/logo.svg"
import { Modal, Button } from 'react-bootstrap';
import { API } from "../../Config";
import ReactPaginate from "react-paginate";

const Myjhankis = () => {
    const navigate = useHistory()
    const [jhanki, setJhankiData] = useState([]);
    const [visibladd, setVisibleedit] = useState(false);
    const [imageToView, setImageToView] = useState('');
    const [page, setPage] = useState(1);
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(3);

    useEffect(() => {
        GetJhankiData(page, perPage);
    }
        , []);
    const GetJhankiData = async (page, perPage) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
            const resp = await fetch(`${API.localHost}/get_all_jhanki?page=${page}&limit=${perPage}`)
            const respdata = await resp.json()
            console.log(respdata, "respdata")
            if (respdata.status === 200) {
                const reponse = respdata.data;
                const total = reponse[0].totalCount[0].total;
                const slice = total / perPage;
                const pages = Math.ceil(slice);
                console.log(pages, ">>>>>>>>>>>>>> pages")
                setPageCount(pages)
                setJhankiData(reponse[0].data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const deleteItem = async (id) => {
        try {
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`${API.localHost}/delete_jhanki?id=${id}`, requestOptions)
            const res = await response.json()
            console.log(res, "jhanki res")
            if (res.status === 200) {
                GetJhankiData(page, perPage);
            }
        } catch (error) {
            console.log(error)
        }
    }
    const JhankiDetails = async (id) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`${API.localHost}/get_jhanki_by_id?id=${id}`, requestOptions)
            const res = await response.json()
            console.log(res, "jhanki res")
            if (res.status === 200) {
                const reponse = res.result
                localStorage.setItem('jhankiid', reponse._id);
                navigate.push('/JhankiDetails')
            }
        } catch (error) {
            console.log(error)
        }
    }
    const ViewImage = (image) => {
        setVisibleedit(true)
        setImageToView(image)
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage + 1);
        GetJhankiData(selectedPage + 1, perPage);
    };
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Jhanki Dashboard</h1>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/Menu">
                                            <i className="fas fa-home" /> Admin Panel
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard">
                                            <i className="fas fa-dashboard" /> Dashboard
                                        </Link>
                                    </li>
                                </ol>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <table className="table table-bordered">
                                <thead className="thead-dark">
                                    <tr className="table-info">
                                        <th>#</th>
                                        <th>User Name</th>
                                        <th>Jhanki Image</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jhanki?.length > 0 ?
                                        <>

                                            {jhanki?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.user_data[0]?.first_name} {item.user_data[0]?.last_name}</td>
                                                    <td>
                                                        <img
                                                            src={`${API.baseUrl}/uploads/JhankiImages/` + item?.jhanki_image}
                                                            // src={logo}
                                                            // className=""
                                                            style={{ width: '100px', height: '100px' }}
                                                            alt="User Image"
                                                        />
                                                        <button onClick={() => ViewImage(item?.jhanki_image)} className="btn btn-warning mx-3"><i className="fas fa-eye" /></button>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-primary" onClick={() => { JhankiDetails(item._id); }}>Deactivate</button>
                                                        {' '}
                                                        <button className="btn btn-danger mx-1" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteItem(item._id) }}>Delete</button>
                                                    </td>
                                                </tr>
                                            ))}

                                        </>
                                        :
                                        <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                                    }

                                </tbody>
                                <div className="card-footer">
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-end"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            </table>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                <Modal size='lg' show={visibladd} onHide={() => setVisibleedit(false)}>
                    <Modal.Header >
                        <Modal.Title>Add User</Modal.Title>
                        <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                            X
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="card">
                                        <div className="card-body">
                                            <img
                                                src={`${API.baseUrl}/uploads/JhankiImages/` + imageToView}
                                                // src={logo}
                                                style={{ width: '550px', height: '350px' }}
                                                alt="User Image"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* /.content */}
            </div>
            <Footer />
        </div>
    )
}

export default Myjhankis
