import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { API } from "../../Config";
export default function Header() {
  const navigate = useHistory()
  const [adminDetails, setAdminDetails] = useState([]);
  useEffect(() => {
    const id = localStorage.getItem('adminid')
    if (!id) {
      navigate.push('/')
    } else {
      GetAdminDetails(id)
    }

  }, []);
  const GetAdminDetails = async (id) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    const response = await fetch(`${API.localHost}/get_admin_by_id?id=${id}`, requestOptions)
    const res = await response.json()
    console.log(res, "res")
    if (res.status === 200) {
      const reponse = res.data
      setAdminDetails(reponse)
    }

  }
  const Logout = () => {
    localStorage.clear()
    navigate.push('/')

  }
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="#" style={{ paddingTop: '3px' }}>
            <div className="user-panel pb-3 mb-3 d-flex">
              <div className="image">
                <img
                  src={`${API.baseUrl}/uploads/userImages/` + adminDetails?.profile_image}
                  className="img-circle elevation-2"
                  alt="User Image"
                  style={{ height: "auto", width: "2.1rem" }}
                />
              </div>
              <div className="info">
                <a href="#" className="d-block" style={{ color: 'rgb(92 92 92)' }}>
                  {adminDetails.first_name} {adminDetails.laset_name} <i class="fa fa-caret-down" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="#" onClick={() => Logout()} className="dropdown-item">
              <i className="fa fa-sign-out" aria-hidden="true" />Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}
