import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import ReactPaginate from "react-paginate";
import { API } from "../../Config";


const Myusers = () => {
    // const navigate = useHistory()
    const [users, setusersData] = useState([]);
    const [editData, setEditData] = useState([]);
    const [visibleedit, setVisibleedit] = useState(false);
    const [visibladd, setVisibleAdd] = useState(false);
    const [pageCount, setPageCount] = useState(3);
    const [page, setPage] = useState(1);
    const [perPage] = useState(10);


    useEffect(() => {
        GetusersData(page, perPage);
    }
        , []);
    const GetusersData = async (page, perPage) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`${API.localHost}/get_all_users?page=${page}&limit=${perPage}`, requestOptions)
            const res = await response.json()
            console.log(res, "users res")
            if (res.status === 200) {
                const reponse = res.data;
                const total = reponse[0].totalCount[0].total;
                const slice = total / perPage;
                const pages = Math.ceil(slice);
                setPageCount(pages)
                setusersData(reponse[0].data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const deleteItem = async (id) => {
        try {
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`${API.localHost}/delete_user?id=${id}`, requestOptions)
            const res = await response.json()
            console.log(res, "users delete res")
            if (res.status === 200) {
                GetusersData(page, perPage);
            }
        } catch (error) {
            console.log(error)
        }
    }
    const usersDetails = async (id) => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`${API.localHost}/get_user_by_id?id=${id}`, requestOptions)
            const res = await response.json()
            console.log(res, "users res")
            if (res.status === 200) {
                const reponse = res.data
                setEditData(reponse)
                setVisibleedit(true)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const updateSubmit = async (e) => {
        e.preventDefault()
        const formdata = new FormData(e.target)

        try {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Object.fromEntries(formdata)),
            };
            const response = await fetch(`${API.localHost}/update_user?id=${editData._id}`, requestOptions)
            const res = await response.json()

            if (res.status === 200) {
                setVisibleedit(false)
                alert(res.message)
                GetusersData(page, perPage);
            } else {
                alert(res.message)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const Add = async () => {
        setVisibleAdd(true)
    }
    const AddAdmin = async (e) => {
        e.preventDefault()
        const formdata = new FormData(e.target)

        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Object.fromEntries(formdata)),
            };
            const response = await fetch(`${API.localHost}/add_user`, requestOptions)
            const res = await response.json()

            if (res.status === 200) {
                setVisibleAdd(false)
                alert(res.message)
                GetusersData(page, perPage);
            } else {
                alert(res.message)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage + 1);
        GetusersData(selectedPage + 1, perPage);
    };
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Users Dashboard</h1>
                                {/* <button className="btn btn-success my-1" onClick={() => Add()}>Add User</button> */}
                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/Menu">
                                            <i className="fas fa-home" /> Admin Panel
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard">
                                            <i className="fas fa-dashboard" /> Dashboard
                                        </Link>
                                    </li>
                                </ol>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <table className="table table-bordered">
                                <thead className="thead-dark">
                                    <tr className="table-info">
                                        <th>#</th>
                                        <th> Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users?.length > 0 ?
                                        <>

                                            {users?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.first_name} {item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>
                                                        <button className="btn btn-primary" onClick={() => { usersDetails(item._id); }}>Edit</button>

                                                        {' '}
                                                        <button className="btn btn-danger mx-1" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteItem(item._id) }}>Delete</button>
                                                    </td>
                                                </tr>
                                            ))}

                                        </>
                                        :
                                        <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                                    }

                                </tbody>
                            </table>
                            <div className="card-footer">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-end"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>

                <Modal size='lg' show={visibleedit} onHide={() => setVisibleedit(false)}>
                    <Modal.Header >
                        <Modal.Title>Edit User</Modal.Title>
                        <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                            Close
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form action="/" method="POST" onSubmit={updateSubmit}>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">First Name</label>
                                                            <input type="text" className="form-control" placeholder="First Name" name="first_name" autoComplete="off" defaultValue={editData.first_name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Last Name</label>
                                                            <input type="text" className="form-control" placeholder="Last Name" name="last_name" autoComplete="off" defaultValue={editData.last_name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Email</label>
                                                            <input type="text" className="form-control" placeholder="Enter Email" name="email" autoComplete="off" defaultValue={editData.email} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Phone</label>
                                                            <input type="text" className="form-control" placeholder="Phone Number" name="phone" autoComplete="off" defaultValue={editData.phone} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn btn-primary mt-2 submit_all" style={{ float: "right" }}>Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                            Close
                        </Button>
                    </Modal.Footer> */}
                </Modal>
                <Modal size='lg' show={visibladd} onHide={() => setVisibleAdd(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form action="/" method="POST" onSubmit={AddAdmin}>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">First Name</label>
                                                            <input type="text" className="form-control" placeholder="First Name" name="first_name" autoComplete="off" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Last Name</label>
                                                            <input type="text" className="form-control" placeholder="Last Name" name="last_name" autoComplete="off" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Email</label>
                                                            <input type="text" className="form-control" placeholder="Email" name="email" autoComplete="off" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Phone</label>
                                                            <input type="text" className="form-control" placeholder="Phone Number" name="phone" autoComplete="off" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Password</label>
                                                            <input type="text" className="form-control" placeholder="Enter Password" name="password" autoComplete="off" required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn btn-primary mt-2 submit_all" style={{ float: "right" }}>Add</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Footer />
        </div>
    )
}

export default Myusers
