import React, { useEffect } from 'react'
import logo from "../../images/logo.svg"
import { useHistory } from 'react-router-dom';
import { API } from '../../Config';
const Login = () => {
  const navigate = useHistory()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData(e.target)
    const password = data.get('password')
    const email = data.get('email')
    const user = { email, password }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
    };
    const response = await fetch(`${API.localHost}/admin_login`, requestOptions)
    const res = await response.json()
    console.log(res, "res")
    if (res.status === 201) {
      const reponse = res.data
      localStorage.setItem('adminid', reponse._id);
      const usertype = reponse?.user_type
      console.log(reponse)
      // localStorage.setItem('token', response.data.token);
      if (usertype === 'admin') {
        console.log(usertype, "usertype")
        navigate.push('/Main')
      }
      console.log(email, password)
    }
  }
  return (
    <div className="login-page">
      <div className="login-box">
        {/* /.login-logo */}
        <div className="card">
          <div className="card-body login-card-body">
            <div className="login-logo">
              <img src={logo} />
            </div>
            <p className="login-box-msg">Sign in to start your session</p>
            <form className="form" action="/" method="POST" onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  name='email'
                  className="form-control"
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  name='password'
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* /.col */}
                <div className="col-12 mb-2">
                  <button type="submit" className="btn btn-primary btn-block">
                    Log In
                  </button>
                </div>
                {/* /.col */}
              </div>
            </form>
            {/* /.social-auth-links */}
            <p className="mb-1">
              <a href="forgot-password.html">I forgot my password</a>
            </p>
          </div>
          {/* /.login-card-body */}
        </div>
      </div>
    </div>
  )
}
export default Login